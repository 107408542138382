<section id="document-building-blocks-section" class="feature-section">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="exclamation-box-container">
                    <div class="label-container">
                        <span class="exclamation-box-sub-title">Das A & O deiner Gutachten</span>
                        <h3 class="exclamation-box-heading">Textbausteine</h3>
                        <!-- Don't let prettier format the following inline elements (to prevent whitespace between strong tag and comma) -->
                        <!-- prettier-ignore -->
                        <span>
                            Deine Texte machen deine Gutachten erst richtig individuell. Damit das auch in autoiXpert so
                            ist, kannst du den
                            <strong>umfangreichen Standard</strong>, den wir dir mitliefern, beliebig anpassen.
                        </span>
                    </div>

                    <!-- Document building blocks graphic. Each building block is animated (slide in from bottom, then faded out at the end. -->
                    <svg
                        id="document-building-block-graphic"
                        width="184"
                        height="253"
                        viewBox="0 0 184 253"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b_110_427)">
                            <rect x="0.5" width="183" height="253" rx="10" fill="#3DB8EC" />
                            <rect
                                x="1"
                                y="0.5"
                                width="182"
                                height="252"
                                rx="9.5"
                                stroke="white"
                                stroke-opacity="0.23" />
                            <g class="building-block" opacity="0.5" filter="url(#filter1_b_110_427)">
                                <rect x="20.5" y="30" width="143" height="40" rx="10" fill="white" fill-opacity="0.5" />
                                <rect
                                    x="21"
                                    y="30.5"
                                    width="142"
                                    height="39"
                                    rx="9.5"
                                    stroke="white"
                                    stroke-opacity="0.23" />
                            </g>
                            <g class="building-block" opacity="0.5" filter="url(#filter2_b_110_427)">
                                <rect x="20.5" y="80" width="143" height="40" rx="10" fill="white" fill-opacity="0.5" />
                                <rect
                                    x="21"
                                    y="80.5"
                                    width="142"
                                    height="39"
                                    rx="9.5"
                                    stroke="white"
                                    stroke-opacity="0.23" />
                            </g>
                            <g class="building-block" opacity="0.5" filter="url(#filter3_b_110_427)">
                                <rect
                                    x="20.5"
                                    y="130"
                                    width="143"
                                    height="40"
                                    rx="10"
                                    fill="white"
                                    fill-opacity="0.5" />
                                <rect
                                    x="21"
                                    y="130.5"
                                    width="142"
                                    height="39"
                                    rx="9.5"
                                    stroke="white"
                                    stroke-opacity="0.23" />
                            </g>
                            <g class="building-block" opacity="0.5" filter="url(#filter4_b_110_427)">
                                <rect
                                    x="20.5"
                                    y="180"
                                    width="143"
                                    height="40"
                                    rx="10"
                                    fill="white"
                                    fill-opacity="0.5" />
                                <rect
                                    x="21"
                                    y="180.5"
                                    width="142"
                                    height="39"
                                    rx="9.5"
                                    stroke="white"
                                    stroke-opacity="0.23" />
                            </g>
                        </g>
                        <defs>
                            <filter
                                id="filter0_b_110_427"
                                x="-19.5"
                                y="-20"
                                width="223"
                                height="293"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_427" />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_backgroundBlur_110_427"
                                    result="shape" />
                            </filter>
                            <filter
                                id="filter1_b_110_427"
                                x="7.5"
                                y="17"
                                width="169"
                                height="66"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_427" />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_backgroundBlur_110_427"
                                    result="shape" />
                            </filter>
                            <filter
                                id="filter2_b_110_427"
                                x="7.5"
                                y="67"
                                width="169"
                                height="66"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_427" />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_backgroundBlur_110_427"
                                    result="shape" />
                            </filter>
                            <filter
                                id="filter3_b_110_427"
                                x="7.5"
                                y="117"
                                width="169"
                                height="66"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_427" />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_backgroundBlur_110_427"
                                    result="shape" />
                            </filter>
                            <filter
                                id="filter4_b_110_427"
                                x="7.5"
                                y="167"
                                width="169"
                                height="66"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.5" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_427" />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_backgroundBlur_110_427"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</section>
