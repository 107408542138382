import { formatNumber, roundToHalf } from './price-calculator-utils';

export interface AudatexCasePackage {
    name: string;
    numberOfCasesIncludedAnnually: number;
    monthlyFee: number;
    priceOfAdditionalCase: number;
}

// Package Pricing
export const AUDATEX_PACKAGES: Array<AudatexCasePackage> = [
    {
        name: 'pro Abruf',
        numberOfCasesIncludedAnnually: 0,
        monthlyFee: 0,
        priceOfAdditionalCase: 25,
    },
    {
        name: 'XXS',
        numberOfCasesIncludedAnnually: 20,
        monthlyFee: 84,
        priceOfAdditionalCase: 49,
    },
    {
        name: 'XS',
        numberOfCasesIncludedAnnually: 50,
        monthlyFee: 182,
        priceOfAdditionalCase: 49,
    },
    {
        name: 'S',
        numberOfCasesIncludedAnnually: 100,
        monthlyFee: 241,
        priceOfAdditionalCase: 29,
    },
    {
        name: '200',
        numberOfCasesIncludedAnnually: 200,
        monthlyFee: 418,
        priceOfAdditionalCase: 29,
    },
    {
        name: 'M',
        numberOfCasesIncludedAnnually: 300,
        monthlyFee: 484,
        priceOfAdditionalCase: 29,
    },
    {
        name: 'L',
        numberOfCasesIncludedAnnually: 500,
        monthlyFee: 787,
        priceOfAdditionalCase: 29,
    },
    {
        name: '750',
        numberOfCasesIncludedAnnually: 750,
        monthlyFee: 1086,
        priceOfAdditionalCase: 19,
    },
    {
        name: 'XL',
        numberOfCasesIncludedAnnually: 1000,
        monthlyFee: 1394,
        priceOfAdditionalCase: 19,
    },
    {
        name: 'XXL',
        numberOfCasesIncludedAnnually: 1500,
        monthlyFee: 2060,
        priceOfAdditionalCase: 19,
    },
    {
        name: 'XXXL',
        numberOfCasesIncludedAnnually: 2000,
        monthlyFee: 2605,
        priceOfAdditionalCase: 19,
    },
];

//*****************************************************************************
//  Calculate Annual Fee
//****************************************************************************/
/**
 * Based on a given plan (package), calculate the total annual fee.
 */
export const caclulateAnnualFee = (casePackage: AudatexCasePackage, reportsPerMonth: number) => {
    const numberOfAdditionalCases = getAdditionalCasesPerYear(casePackage, reportsPerMonth);

    return casePackage.monthlyFee * 12 + numberOfAdditionalCases * casePackage.priceOfAdditionalCase;
};
export const caclulateMonthlyFee = (casePackage: AudatexCasePackage, reportsPerMonth: number) => {
    return caclulateAnnualFee(casePackage, reportsPerMonth) / 12;
};
export const getAdditionalCasesPerYear = (casePackage: AudatexCasePackage, reportsPerMonth: number) => {
    const numberOfReportsPerYear = reportsPerMonth * 12;
    return Math.max(roundToHalf(numberOfReportsPerYear - casePackage.numberOfCasesIncludedAnnually), 0);
};
export const getAdditionalCasesPerMonth = (casePackage: AudatexCasePackage, reportsPerMonth: number) => {
    return formatNumber(roundToHalf(Math.max(reportsPerMonth - casePackage.numberOfCasesIncludedAnnually / 12, 0)));
};
/////////////////////////////////////////////////////////////////////////////*/
//  END Calculate Annual Fee
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Lowest Fee / Best Price
/////////////////////////////////////////////////////////////////////////////*/
export const bestValueAudatexPackage = (reportsPerMonth: number, packages: AudatexCasePackage[] = AUDATEX_PACKAGES) => {
    const lowestAnnualFee = Math.min(
        ...packages.map((casePackage) => caclulateAnnualFee(casePackage, reportsPerMonth)),
    );

    return packages.find((casePackage) => caclulateAnnualFee(casePackage, reportsPerMonth) === lowestAnnualFee);
};

/////////////////////////////////////////////////////////////////////////////*/
//  END Lowest Fee / Best Price
/////////////////////////////////////////////////////////////////////////////*/
