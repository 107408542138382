import {
    afterNextRender,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    model,
    PLATFORM_ID,
    ViewChild,
} from '@angular/core';
import { PricingFaqComponent } from '../pricing-faq/pricing-faq.component';
import { ExclamationBoxComponent } from '../../../shared/components/exclamation-box/exclamation-box.component';
import { MatLegacySliderChange, MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySlideToggleChange, MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FormsModule } from '@angular/forms';
import {
    bestValueAudatexPackage,
    caclulateMonthlyFee,
} from '../../../shared/lib/price-calculation/audatex-price-calculator-functions';
import { FormatPricePipe } from '../../../shared/pipe/format-price.pipe';
import { isPlatformBrowser, NgIf } from '@angular/common';
import { fadeInAndOutAnimation } from '../../../shared/animations/fade-in-and-out.animation';
import {
    lowestFirstYearFee,
    MONTHLY_PAYMENT_CYCLE_FEE,
} from '../../../shared/lib/price-calculation/dat-price-calculator-functions';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { registerSmoothScrollingForRelativeLinks } from '../../../shared/lib/smooth-scrolling';
import { initializeTooltipster } from '../../../shared/lib/initialize-tooltipster';
import {
    AUDATEX_ADDON_COSTS,
    AUTOIXPERT_BASE_ACCOUNT_FEE,
    CALCULATION_PROVIDER_QUERY_PARAM_NAME,
    CalculationProvider,
    COST_PER_ADDITIONAL_USER,
    COST_PER_ADDITIONAL_USER_AUDATEX_ADDON,
    INCLUDE_MARKET_AND_RESIDUAL_VALUE_QUERY_PARAM_NAME,
    MARKET_VALUE_ANALYSIS_COST,
    NUMBER_OF_USERS_QUERY_PARAM_NAME,
    REPORTS_PER_MONTH_AUDATEX_QUERY_PARAM_NAME,
    REPORTS_PER_MONTH_QUERY_PARAM_NAME,
    RESIDUAL_VALUE_REQUEST_COST,
} from '../../../shared/lib/price-calculation/autoixpert-price-calculator-constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { fadeInAndSlideVerticallyAnimation } from '../../../shared/animations/fade-in-and-slide-vertically.animation';
import { slideInAndOutVertically } from '../../../shared/animations/slide-in-and-out-vertical.animation';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'ax-gesamtkostenrechner',
    standalone: true,
    imports: [
        PricingFaqComponent,
        ExclamationBoxComponent,
        MatLegacySliderModule,
        MatLegacyInputModule,
        MatButtonToggleModule,
        MatIconModule,
        MatLegacySlideToggleModule,
        FormsModule,
        FormatPricePipe,
        NgIf,
        RouterLink,
        MatTooltipModule,
    ],
    templateUrl: './gesamtkostenrechner.component.html',
    styleUrl: './gesamtkostenrechner.component.scss',
    animations: [
        fadeInAndOutAnimation(),
        fadeInAndSlideVerticallyAnimation({ fast: true, duration: 200 }),
        slideInAndOutVertically(),
    ],
})
export class GesamtkostenrechnerComponent {
    protected readonly titleService = inject(Title);
    protected readonly metaService = inject(Meta);

    @ViewChild('customNumberOfUsersInput', { read: ElementRef })
    customNumberOfUsersButton: ElementRef<HTMLInputElement>;
    @ViewChild('resultsSection', { read: ElementRef }) resultsSection: ElementRef<HTMLElement>;
    @ViewChild('marketAndResidualValueTooltip', { read: ElementRef })
    marketAndResidualValueTooltipElement: ElementRef<HTMLElement>;

    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly platformId = inject(PLATFORM_ID);

    protected numberOfReportsPerMonth = model(15);
    protected numberOfReportsPerMonthAudatex = model(0);
    protected numberOfUsers = model<number>(null);
    protected calculationProvider = model<CalculationProvider>(null);

    protected showCustomNumberOfUsersInputField: boolean = false;
    protected hideCalculationProviderCostsInfoNote: boolean = false;
    protected includeMarketAndResidualValue = model(false);

    protected numberOfAdditionalUsers = computed(() => {
        return Math.max(0, (this.numberOfUsers() ?? 1) - 1);
    });

    protected additionalUsersTotalCost = computed(() => {
        return this.numberOfAdditionalUsers() * COST_PER_ADDITIONAL_USER;
    });

    protected audatexAddonTotalCost = computed(() => {
        if (this.calculationProvider() === 'audatex' || this.calculationProvider() === 'both') {
            return AUDATEX_ADDON_COSTS + this.numberOfAdditionalUsers() * COST_PER_ADDITIONAL_USER_AUDATEX_ADDON;
        }

        return 0;
    });

    protected audatexTotalCost = computed(() => {
        if (this.calculationProvider() === 'audatex') {
            const bestPackage = bestValueAudatexPackage(this.numberOfReportsPerMonth());
            return caclulateMonthlyFee(bestPackage, this.numberOfReportsPerMonth());
        } else if (this.calculationProvider() === 'both') {
            const bestPackage = bestValueAudatexPackage(this.numberOfReportsPerMonthAudatex());
            return caclulateMonthlyFee(bestPackage, this.numberOfReportsPerMonthAudatex());
        } else {
            return 0;
        }
    });

    protected datTotalCost = computed(() => {
        if (this.calculationProvider() === 'dat') {
            return (
                lowestFirstYearFee(
                    this.numberOfUsers() || 1,
                    this.numberOfReportsPerMonth().toString(),
                    '0',
                    false,
                    false,
                ) /
                    12 +
                MONTHLY_PAYMENT_CYCLE_FEE
            );
        } else if (this.calculationProvider() === 'both') {
            const numberOfDatReports = this.numberOfReportsPerMonth() - this.numberOfReportsPerMonthAudatex();
            return (
                lowestFirstYearFee(this.numberOfUsers() || 1, numberOfDatReports.toString(), '0', false, false) / 12 +
                MONTHLY_PAYMENT_CYCLE_FEE
            );
        } else {
            return 0;
        }
    });

    /**
     * Sub-total is the sum of all monthly costs excluding the market and residual value request costs.
     */
    protected subTotal = computed(() => {
        return this.subTotalAutoixpertOnly() + this.audatexTotalCost() + this.datTotalCost();
    });

    protected subTotalAutoixpertOnly = computed(() => {
        return AUTOIXPERT_BASE_ACCOUNT_FEE + this.additionalUsersTotalCost() + this.audatexAddonTotalCost();
    });

    protected subTotalPerReport = computed(() => {
        return this.subTotal() / this.numberOfReportsPerMonth();
    });

    protected subTotalPerReportAutoixpert = computed(() => {
        return this.subTotalAutoixpertOnly() / this.numberOfReportsPerMonth();
    });

    protected estimatedNumberOfResidualValueRequests = computed(() => {
        return Math.ceil(this.numberOfReportsPerMonth() / 3);
    });

    protected marketAndResidualValueCosts = computed(() => {
        if (this.includeMarketAndResidualValue()) {
            const numberOfResidualValueRequests = this.estimatedNumberOfResidualValueRequests();
            const numberOfMarketAnalyses = this.numberOfReportsPerMonth();
            return (
                numberOfResidualValueRequests * RESIDUAL_VALUE_REQUEST_COST +
                numberOfMarketAnalyses * MARKET_VALUE_ANALYSIS_COST
            );
        }

        return 0;
    });

    protected marketAndResidualValueRequestTooltip = computed(() => {
        const marketValueLabel = this.numberOfReportsPerMonth() > 1 ? 'Marktwertanalysen' : 'Marktwertanalyse';
        const residualValueRequestLabel =
            this.estimatedNumberOfResidualValueRequests() > 1 ? 'Restwertinseraten' : 'Restwertinserat';
        return `Geschätzter Betrag bei ${this.numberOfReportsPerMonth()} ${marketValueLabel} à 2,50 € und ${this.estimatedNumberOfResidualValueRequests()} ${residualValueRequestLabel} à 18 €.`;
    });

    protected totalCosts = computed(() => {
        return this.subTotal() + this.marketAndResidualValueCosts();
    });

    protected totalCostsPerReport = computed(() => {
        return this.totalCosts() / this.numberOfReportsPerMonth();
    });

    constructor() {
        this.titleService.setTitle('Gesamtkostenrechner');
        this.metaService.addTag({
            name: 'description',
            content:
                'Berechne deine individuellen Kosten für die Kfz-Gutachter-Software autoiXpert inklusive Kalkulations-, Markt- und Restwertanbietern.',
        });

        afterNextRender(() => {
            registerSmoothScrollingForRelativeLinks();
        });

        if (isPlatformBrowser(this.platformId)) {
            effect(() => {
                // This effect is called everytime a signal used within this function (e.g. marketAndResidualValueRequestTooltip)
                // changes its value. Then we update the dynamic tooltip of the market and residual value help indicator.
                const tooltip = this.marketAndResidualValueRequestTooltip();
                const tooltipsterInitialized = document.querySelector('.tooltipstered');
                if (this.marketAndResidualValueTooltipElement && tooltipsterInitialized) {
                    ($(this.marketAndResidualValueTooltipElement.nativeElement) as any).tooltipster('content', tooltip);
                }
            });

            // Load parameters from URL
            this.loadSettingsFromQueryParameters();

            // Update the URL when input values for the calculator change
            this.setupEffectToUpdateQueryParameters();
        }
    }

    /**
     * Everytime the user changes the settings in the total cost calculator, we update the URL. That way
     * the user can always share the URL and the settings will be restored when opening the page again.
     */
    private setupEffectToUpdateQueryParameters() {
        effect(() => {
            // This effect is called everytime a parameter for the calculation changed and updates the URL.
            const queryParams: Params = {
                [NUMBER_OF_USERS_QUERY_PARAM_NAME]: this.numberOfUsers(),
                [REPORTS_PER_MONTH_QUERY_PARAM_NAME]: this.numberOfReportsPerMonth(),
                [REPORTS_PER_MONTH_AUDATEX_QUERY_PARAM_NAME]: this.numberOfReportsPerMonthAudatex(),
                [CALCULATION_PROVIDER_QUERY_PARAM_NAME]: this.calculationProvider(),
                [INCLUDE_MARKET_AND_RESIDUAL_VALUE_QUERY_PARAM_NAME]: this.includeMarketAndResidualValue(),
            };

            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams,
                replaceUrl: true,
                queryParamsHandling: 'merge',
            });
        });
    }

    /**
     * Load the previous settings for the total cost calculator from the query parameters of the URL.
     * This is helpful if the URL is shared with clients or friends.
     */
    private loadSettingsFromQueryParameters() {
        const queryParams = this.activatedRoute.snapshot.queryParamMap;

        const numberOfUsers = queryParams.get(NUMBER_OF_USERS_QUERY_PARAM_NAME)
            ? parseInt(queryParams.get(NUMBER_OF_USERS_QUERY_PARAM_NAME))
            : null;
        this.numberOfUsers.set(numberOfUsers);
        if (numberOfUsers > 3) {
            this.showCustomNumberOfUsersInputField = true;
        }

        const numberOfReports = queryParams.get(REPORTS_PER_MONTH_QUERY_PARAM_NAME)
            ? parseInt(queryParams.get(REPORTS_PER_MONTH_QUERY_PARAM_NAME))
            : 15;
        this.numberOfReportsPerMonth.set(numberOfReports);

        const numberOfReportsAudatex = queryParams.get(REPORTS_PER_MONTH_AUDATEX_QUERY_PARAM_NAME)
            ? parseInt(queryParams.get(REPORTS_PER_MONTH_AUDATEX_QUERY_PARAM_NAME))
            : 0;
        this.numberOfReportsPerMonthAudatex.set(numberOfReportsAudatex);

        const calculationProvider =
            (queryParams.get(CALCULATION_PROVIDER_QUERY_PARAM_NAME) as CalculationProvider) ?? null;
        this.calculationProvider.set(calculationProvider);

        const includeMarketAndResidualValue =
            queryParams.get(INCLUDE_MARKET_AND_RESIDUAL_VALUE_QUERY_PARAM_NAME) === 'true';
        this.includeMarketAndResidualValue.set(includeMarketAndResidualValue);
    }

    protected numberOfUsersChanged(numberOfUsers: number): void {
        this.showCustomNumberOfUsersInputField = false;
        this.numberOfUsers.set(numberOfUsers);
    }

    protected customNumberOfUsersSelected(): void {
        this.showCustomNumberOfUsersInputField = true;
        this.numberOfUsers.set(null);
        setTimeout(() => {
            this.customNumberOfUsersButton.nativeElement.focus();
        }, 0);
    }

    protected calculationProviderChanged(selectedValue: CalculationProvider): void {
        if (selectedValue === this.calculationProvider()) {
            // Clicking a selected option deselects it
            this.calculationProvider.set(null);
            return;
        }

        // Save the selected option
        this.calculationProvider.set(selectedValue);

        if (selectedValue === 'both') {
            this.resetDefaultCalculationProviderDistribution();
        }

        setTimeout(() => {
            // Re-initialize the tooltips, because the DAT or Audatex cards got added to the DOM (*ngIf)
            initializeTooltipster();
        }, 0);
    }

    protected includeMarketAndResidualValueChanged(selectedValue: MatLegacySlideToggleChange): void {
        if (selectedValue.checked) {
            setTimeout(() => {
                // Re-initialize the tooltips, because the market and residual value request card got added to the DOM (*ngIf)
                initializeTooltipster();
            }, 0);
        }
    }

    protected numberOfReportsChanged(): void {
        if (this.calculationProvider() === 'both') {
            this.resetDefaultCalculationProviderDistribution();
        }
    }

    protected reportsPerMonthSliderValueChanged(change: MatLegacySliderChange): void {
        // Update the number of reports immediately while dragging the handle
        this.numberOfReportsPerMonth.set(change.value);
        this.numberOfReportsChanged();
    }

    protected reportsPerMonthAudatexSliderValueChanged(change: MatLegacySliderChange): void {
        // Update the number of reports immediately while dragging the handle
        this.numberOfReportsPerMonthAudatex.set(change.value);
    }

    /**
     * When the user has selected to use both providers (DAT & Audatex) and changes the number of reports per month, this
     * function updates the distribution between DAT and Audatex calculations (always chooses the middle).
     */
    protected resetDefaultCalculationProviderDistribution(): void {
        this.numberOfReportsPerMonthAudatex.set(Math.ceil(this.numberOfReportsPerMonth() / 2));
    }

    /**
     * Directly navigate to the DAT or Audatex calculator in a new tab with the current settings (e.g. number of reports)
     */
    protected navigateTo(route: string): void {
        const url = new URL(route, window.location.href);
        url.searchParams.set(REPORTS_PER_MONTH_QUERY_PARAM_NAME, this.numberOfReportsPerMonth().toString());
        url.searchParams.set(NUMBER_OF_USERS_QUERY_PARAM_NAME, (this.numberOfUsers() ?? 1).toString());
        window.open(`${url}`, '_blank');
    }

    /**
     * Scroll to the results section on mobile (because the input section and results (prices) sections are stacked on top of each other.
     */
    protected scrollToResults(): void {
        this.resultsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    protected readonly AUTOIXPERT_BASE_ACCOUNT_FEE = AUTOIXPERT_BASE_ACCOUNT_FEE;
    protected readonly COST_PER_ADDITIONAL_USER = COST_PER_ADDITIONAL_USER;
}
