import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ax-exclamation-box',
    standalone: true,
    imports: [NgIf],
    templateUrl: './exclamation-box.component.html',
    styleUrl: './exclamation-box.component.scss',
})
export class ExclamationBoxComponent {
    @Input() headline: string = 'Bereit zum Durchstarten?';
    @Input() subtitle: string = '';
    @Input() description: string = '';
    @Input() buttonTitle: string = '30 Tage kostenfrei testen';
    @Input() secondButtonTitle: string = '';
    @Input() buttonLink: string = '/Registrierung';
    @Input() secondButtonLink: string = '';
    @Input() limitTextWidthTo50Percent: boolean = false;
    @Input() noPadding: boolean = false;
}
