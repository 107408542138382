<section id="pricing-intro-section">
    <div class="container">
        <div class="row text-center justify-content-center">
            <div class="col-md-7">
                <h3 class="additional-headline">Preise Audatex Qapter von Solera</h3>
                <h1 id="pricing-intro-headline">Was kostet Audatex Qapter für Kfz-Sachverständige?</h1>
                <p id="pricing-intro-tagline">
                    Kalkuliere Schäden mit Qapter online im Browser und greife
                    <br class="d-none d-md-block" />
                    auf die Datenbasis zu, mit der 60 % aller Schäden
                    <br class="d-none d-md-block" />
                    weltweit kalkuliert werden.
                </p>
            </div>
        </div>
    </div>
</section>

<div>
    <!--============================================-->
    <!-- Price Calculator -->
    <!--============================================-->
    <section id="plans-section">
        <div class="container">
            <div class="subscription-plans-container row justify-content-center">
                <div id="calculator-inputs-column" class="col-md mb-5 mb-md-0">
                    <!--============================================-->
                    <!-- Calculator Inputs -->
                    <!--============================================-->
                    <div id="price-calculator-inputs-wrapper">
                        <a
                            id="price-calculator-audatex-logo"
                            href="https://www.audatex.de"
                            target="_blank"
                            rel="noopener">
                            <img src="/assets/images/logos/solera-white.svg" alt="Logo Solera Audatex" />
                        </a>
                        <div id="price-calculator-inputs-container">
                            <div class="price-calculator-input-container">
                                <label for="reports-per-month">Kalkulationen pro Monat</label>
                                <input
                                    type="text"
                                    id="reports-per-month"
                                    [(ngModel)]="reportsPerMonth"
                                    placeholder="Bitte ausfüllen"
                                    (input)="initializeTooltips()"
                                    (keydown)="handleArrowUpAndDownKeys($event, 'reportsPerMonth')" />
                            </div>

                            <!--********** Reports per Year **********-->
                            @if (reportsPerMonth) {
                                <div class="reports-per-year">
                                    = {{ roundToHalf((parseInt(reportsPerMonth, 0) || 0) * 12) }}
                                    pro Jahr
                                </div>
                            } @else {
                                <div>&nbsp;</div>
                            }

                            <div id="in-cooperation-with-autoixpert-container">
                                in Kooperation mit
                                <a href="/">
                                    <img
                                        id="in-cooperation-with-autoixpert-logo"
                                        src="/assets/images/logo-autoixpert-invertiert-blaues-x.svg"
                                        alt="autoiXpert-Logo Software für Sachverständige & Gutachter" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--============================================-->
                    <!-- END Calculator Inputs -->
                    <!--============================================-->
                </div>

                @if (!calculationInputsComplete()) {
                    <div class="col-md d-flex align-items-center justify-content-center text-center">
                        <div id="missing-inputs-placeholder">
                            <h3>
                                Berechne die Kosten für deinen
                                <br class="d-none d-md-block" />
                                Zugang zu Audatex Qapter
                            </h3>
                            <p>Fülle alle Felder im blauen Kasten aus.</p>
                        </div>
                    </div>
                }
                @if (calculationInputsComplete()) {
                    <!--============================================-->
                    <!-- Packages -->
                    <!--============================================-->
                    @for (casePackage of visiblePackages(); track casePackage.name) {
                        <div class="col-md">
                            <div
                                class="audatex-plan-container"
                                [ngClass]="{
                                    monthly: paymentCycleMonthly,
                                    annually: !paymentCycleMonthly,
                                    'best-plan':
                                        bestValueAudatexPackage(parseInt(reportsPerMonth, 10) || 0) === casePackage
                                }">
                                <div class="plan-title-label">Tarif</div>
                                <h2 class="audatex-plan-title">{{ casePackage.name }}</h2>

                                <!--********** Included Items **********-->
                                <div class="audatex-included-items-container">
                                    <!--============================================-->
                                    <!-- Annual Pricing -->
                                    <!--============================================-->
                                    @if (!paymentCycleMonthly) {
                                        <!--********** Row 1: Included cases **********-->
                                        <div class="audatex-plan-feature">
                                            {{ casePackage.numberOfCasesIncludedAnnually }}
                                            Inklusivvorgänge pro Jahr
                                        </div>

                                        <!--********** Spacer **********-->
                                        <div class="audatex-plan-feature">&nbsp;</div>

                                        <!--********** Spacer **********-->
                                        <div class="audatex-plan-feature">&nbsp;</div>

                                        <!--********** Row 2: Additional Calls per Year **********-->
                                        @if (getAdditionalCasesPerYear(casePackage, reportsPerMonthAsNumber())) {
                                            <div class="audatex-plan-feature">
                                                +
                                                {{
                                                    formatNumber(
                                                        getAdditionalCasesPerYear(
                                                            casePackage,
                                                            reportsPerMonthAsNumber()
                                                        )
                                                    )
                                                }}
                                                Zusatzabrufe pro Jahr
                                                <div class="label">
                                                    à {{ formatPrice(casePackage.priceOfAdditionalCase) }}
                                                </div>
                                            </div>
                                        } @else {
                                            <!--********** Spacer **********-->
                                            <div class="audatex-plan-feature">
                                                &nbsp;
                                                <div class="label">&nbsp;</div>
                                            </div>
                                        }

                                        <!--********** Spacer **********-->
                                        <div class="audatex-plan-feature">&nbsp;</div>
                                    }
                                    <!--============================================-->
                                    <!-- END Annual Pricing -->
                                    <!--============================================-->

                                    <!--============================================-->
                                    <!-- Monthly Pricing -->
                                    <!--============================================-->

                                    @if (paymentCycleMonthly) {
                                        <!--********** Row 1: Included cases **********-->
                                        <div class="audatex-plan-feature">
                                            {{ casePackage.numberOfCasesIncludedAnnually }}
                                            Inklusivvorgänge pro Jahr
                                        </div>

                                        <!--********** Row 2: Translation to cases per month **********-->
                                        <div class="label">
                                            @if (casePackage.numberOfCasesIncludedAnnually > 0) {
                                                = ca.
                                                {{ roundNumber(casePackage.numberOfCasesIncludedAnnually / 12) }}
                                                pro Monat
                                            } @else {
                                                Zahlung pro Abruf
                                            }
                                        </div>

                                        <!--********** Spacer **********-->
                                        <div class="audatex-plan-feature">&nbsp;</div>

                                        <!--********** Row 3: Additional cases per month **********-->
                                        @if (getAdditionalCasesPerYear(casePackage, reportsPerMonthAsNumber())) {
                                            <div class="audatex-plan-feature">
                                                +
                                                {{ getAdditionalCasesPerYear(casePackage, reportsPerMonthAsNumber()) }}
                                                Zusatzabrufe pro Jahr
                                                <div class="label">
                                                    à {{ formatPrice(casePackage.priceOfAdditionalCase) }}; ca.
                                                    {{
                                                        getAdditionalCasesPerMonth(
                                                            casePackage,
                                                            reportsPerMonthAsNumber()
                                                        )
                                                    }}
                                                    pro Monat
                                                </div>
                                            </div>
                                        } @else {
                                            <!--********** Spacer **********-->
                                            <div class="audatex-plan-feature">
                                                &nbsp;
                                                <div class="label">&nbsp;</div>
                                            </div>
                                        }

                                        <!--********** Spacer **********-->
                                        <div class="audatex-plan-feature">&nbsp;</div>
                                    }

                                    <!--============================================-->
                                    <!-- END Monthly Pricing -->
                                    <!--============================================-->

                                    <!--============================================-->
                                    <!-- A Case Includes... -->
                                    <!--============================================-->

                                    <!--********** Heading **********-->
                                    <div class="audatex-plan-feature audatex-plan-feature-section-heading">
                                        Ein Vorgang beinhaltet
                                    </div>

                                    <!--********** Items **********-->
                                    <div class="audatex-plan-feature">VIN-Abfrage</div>
                                    <div class="audatex-plan-feature">Kalkulation</div>
                                    <div class="audatex-plan-feature">Bewertung</div>
                                    <div class="audatex-plan-feature">Nutzungsausfall</div>
                                    <!--============================================-->
                                    <!-- END A Case Includes... -->
                                    <!--============================================-->
                                </div>

                                <div class="audatex-plan-prices-container">
                                    <!--============================================-->
                                    <!-- Monthly Price -->
                                    <!--============================================-->
                                    <div class="audatex-plan-price-container monthly">
                                        <div class="audatex-plan-price">
                                            {{
                                                formatPrice(
                                                    caclulateMonthlyFee(casePackage, reportsPerMonthAsNumber()),
                                                    0
                                                )
                                            }}
                                        </div>
                                        <div class="audatex-plan-billing-period">pro Monat</div>
                                    </div>
                                    <!--============================================-->
                                    <!-- END Monthly Price -->
                                    <!--============================================-->

                                    <!--============================================-->
                                    <!-- Annual Price -->
                                    <!--============================================-->
                                    <div class="audatex-plan-price-container annually">
                                        <div class="audatex-plan-price">
                                            {{
                                                formatPrice(
                                                    caclulateAnnualFee(casePackage, reportsPerMonthAsNumber()),
                                                    0
                                                )
                                            }}
                                        </div>
                                        <div class="audatex-plan-billing-period">pro Jahr</div>
                                    </div>
                                    <!--============================================-->
                                    <!-- END Annual Price -->
                                    <!--============================================-->
                                </div>

                                @if (
                                    reportsPerMonth &&
                                    bestValueAudatexPackage(reportsPerMonthAsNumber()) === casePackage
                                ) {
                                    <div @fadeIn class="best-plan-container">Bester Tarif</div>
                                }

                                <!-- Marker: only for new customers -->
                                @if (casePackage.name === 'pro Abruf') {
                                    <div class="new-customer-only-container" matTooltip="Nur für Neukunden erhältlich">
                                        nur für Neukunden
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <!--============================================-->
                    <!-- END Packages -->
                    <!--============================================-->
                }
            </div>
        </div>
    </section>

    @if (calculationInputsComplete()) {
        <section id="payment-period-section">
            <div class="container">
                <div class="row mb-5">
                    <div id="payment-cycle-note" class="col-sm text-center">
                        Alle Preise netto.
                        <br />
                        Vertragslaufzeit 12 Monate.
                        <br />
                        Ein Tarifwechsel ist einmal pro Kalenderjahr kostenlos möglich.
                        <br />
                        Weitere Details auf den allein gültigen Bestellformularen von Solera Audatex.
                    </div>
                </div>
                <div class="row">
                    <div id="billing-period-container" class="col-md-12">
                        <div id="billing-period-title">Preisanzeige</div>
                        <div id="billing-period-selection-container">
                            <div
                                id="billing-period-label-monthly"
                                [class.active]="paymentCycleMonthly"
                                (click)="setPaymentToMonthly()">
                                Monatlich
                            </div>
                            <div id="billing-period-switch-background" (click)="toggleMonthlyAndAnnually($event)">
                                <div
                                    id="billing-period-switch"
                                    [ngClass]="{
                                        monthly: paymentCycleMonthly,
                                        annually: !paymentCycleMonthly
                                    }">
                                    &nbsp;
                                </div>
                            </div>
                            <div
                                id="billing-period-label-annually"
                                [class.active]="!paymentCycleMonthly"
                                (click)="setPaymentToAnnually()">
                                Jährlich
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }

    <section>
        <div class="container">
            @if (!contactFormSent) {
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h2 id="get-in-touch-with-audatex-heading">Kontakt zum Audatex-Vertrieb</h2>
                        <p id="get-in-touch-with-audatex-intro">
                            Lass dich vom Audatex-Vertrieb kontaktieren, der gemeinsam mit dir die passenden Konditionen
                            ermittelt.
                        </p>
                    </div>
                </div>
                <form class="row justify-content-center" (submit)="sendContactForm(); $event.preventDefault()">
                    <div class="col-md-5">
                        <div class="contact-form-input-container">
                            <input
                                [(ngModel)]="organization"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                class="newsletter-input"
                                id="audatex-get-in-touch-organization-input"
                                placeholder="Firma"
                                required />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                [(ngModel)]="firstName"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                class="newsletter-input"
                                id="audatex-get-in-touch-first-name-input"
                                placeholder="Vorname"
                                required />
                            <input
                                [(ngModel)]="lastName"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                class="newsletter-input"
                                id="audatex-get-in-touch-last-name-input"
                                placeholder="Nachname"
                                required />
                        </div>
                        <div class="contact-form-input-container"></div>
                        <div class="contact-form-input-container">
                            <input
                                type="text"
                                class="newsletter-input"
                                id="dat-get-in-touch-street-input"
                                placeholder="Straße und Hausnummer"
                                required
                                [(ngModel)]="streetAndHouseNumber"
                                [ngModelOptions]="{ standalone: true }" />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                [(ngModel)]="zip"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                class="newsletter-input"
                                id="audatex-get-in-touch-zip-input"
                                placeholder="PLZ"
                                required
                                (input)="getCityByZip()" />
                            <input
                                [(ngModel)]="city"
                                [ngModelOptions]="{ standalone: true }"
                                type="text"
                                class="newsletter-input"
                                id="audatex-get-in-touch-city-input"
                                placeholder="Ort"
                                required />
                        </div>
                        <div class="contact-form-input-container multiple-inputs">
                            <input
                                [(ngModel)]="email"
                                [ngModelOptions]="{ standalone: true }"
                                type="email"
                                class="newsletter-input"
                                id="audatex-get-in-touch-email-input"
                                placeholder="E-Mail"
                                required />
                            <input
                                [(ngModel)]="phone"
                                [ngModelOptions]="{ standalone: true }"
                                type="tel"
                                class="newsletter-input"
                                id="audatex-get-in-touch-phone-input"
                                placeholder="Telefon"
                                required />
                        </div>
                        <div id="transmit-usage-data-checkbox-container" class="text-center">
                            <input
                                [(ngModel)]="submitUsageData"
                                [ngModelOptions]="{ standalone: true }"
                                type="checkbox"
                                id="transmit-usage-data"
                                name="transmit-usage-data" />
                            <label for="transmit-usage-data">Daten des Preisrechners übermitteln</label>
                        </div>
                        <div class="contact-form-input-container">
                            <button
                                type="submit"
                                id="audatex-get-in-touch-submit-button"
                                class="newsletter-input primary-button"
                                [title]="contactFormComplete() ? '' : 'Bitte Kontaktdaten angeben'">
                                @if (contactFormSending) {
                                    <img
                                        class="pending-indicator"
                                        src="/assets/images/loading-indicator-white.svg"
                                        alt="" />
                                } @else {
                                    <span>Kontaktanfrage senden</span>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-center text-center" id="audatex-contact-form-legal-info">
                    <div class="col-md-6 label">
                        <p>
                            Greifst du lieber zum Hörer und sprichst persönlich mit jemandem?
                            <br />
                            Hotline:
                            <a href="tel:+49 30 209691 600">+49 30 209691 600</a>
                            <br />
                            <a
                                href="https://www.audatex.de/kontakt/kundenbetreuer-sachverstaendige"
                                target="_blank"
                                rel="noopener">
                                Persönlichen Audatex-Vertriebler finden
                            </a>
                        </p>
                        <p>
                            Deine Kontakdaten werden ausschließlich an uns (autoiXpert) und an die Audatex AUTOonline
                            GmbH übermittelt.
                            <a href="/datenschutz" target="_blank" rel="noopener">Infos zum Datenschutz</a>
                            . Die Preise auf dieser Seite sind ein unverbindliches Hilfsmittel, es gelten stets die
                            offiziellen Preislisten der Audatex AUTOonline GmbH.
                        </p>
                        <p>
                            Dieser Audatex-Preisrechner wird von
                            <a href="/">autoiXpert</a>
                            zur Verfügung gestellt, einem zertifizierten Technologie-Partner von Solera Audatex.
                        </p>
                    </div>
                </div>
            }
            @if (contactFormSent) {
                <div class="row justify-content-center">
                    <div class="col-md-6 text-center">
                        <h2>Anfrage gesendet</h2>
                        <p>Vielen Dank, deine Anfrage wurde an das Vertriebsteam von Audatex weitergeleitet.</p>
                    </div>
                </div>
            }
        </div>
        <div id="newsletter-signup-response-container" class="container"></div>
    </section>

    <section>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div id="features-link-container" class="exclamation-box-container">
                        <h3 class="exclamation-box-heading">Alles, was du als Gutachter brauchst</h3>
                        <div>Textbausteine, Kalkulationen, Buchhaltung & mehr.</div>
                        <a
                            class="exclamation-box-button button-solid light"
                            href="/kfz-sachverstaendigen-software?utm_source=dat-preisrechner&utm_medium=exclamation-box-bottom">
                            autoiXpert entdecken
                        </a>
                        <a class="exclamation-box-button button-solid ghost-button-light" href="/registrierung">
                            Kostenfrei testen
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
