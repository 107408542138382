<header>
    <div class="container">
        <div id="header-navigation-container" class="row justify-content-between">
            <div id="header-logo-container">
                <a href="/">
                    <img id="header-logo" src="/assets/images/logo-autoixpert.svg" alt="autoiXpert Logo" />
                </a>
            </div>
            <!--********** Toggle the menu. Mobile only. **********-->
            <div id="mobile-navigation" class="d-lg-none hamburger--spin" (click)="mobileMenuOpen = !mobileMenuOpen">
                <div class="hamburger hamburger--spin" [class.is-active]="mobileMenuOpen">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </div>
                <img
                    id="mobile-navigation-autoixpert-logo"
                    src="/assets/images/autoixpert-x.svg"
                    alt="autoiXpert-Logo" />
                <div id="mobile-navigation-signup-button-container">
                    <a href="/registrierung">30 Tage testen</a>
                </div>
            </div>

            <div id="header-navigation-row" [class.shown]="mobileMenuOpen">
                <!-- prettier-ignore -->
                <nav id="header-navigation">
                    <a
                        class="header-navigation-element"
                        id="header-navigation-homepage-element"
                        href="/">
                        Startseite
                    </a>
                    <a
                        class="header-navigation-element"
                        href="/Kfz-Sachverstaendigen-Software">
                        Funktionen
                    </a>
                    <a class="header-navigation-element" href="/Fuer-Wechsler">
                        Für Wechsler
                    </a>
                    <a class="header-navigation-element" href="/Preise">
                        Preise
                    </a>
                    <a class="header-navigation-element" href="/Team">
                        Team
                    </a>
                    <a class="header-navigation-element" href="/Kontakt">
                        Kontakt
                    </a>
                    <a
                        class="header-navigation-element"
                        href="https://app.{{ configService.domain }}">
                        Login
                    </a>
                    <a
                        class="header-navigation-element button-solid"
                        href="/Registrierung">
                        Kostenfrei testen
                    </a>
                </nav>
            </div>
        </div>
    </div>
</header>
