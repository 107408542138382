<section id="signup-section" class="feature-section" [class.no-padding]="noPadding">
    <div class="container">
        <div class="row">
            <div class="col-md">
                <div class="exclamation-box-container" [class.text-width-50]="limitTextWidthTo50Percent">
                    <h4 *ngIf="subtitle">{{ subtitle }}</h4>
                    <h3 class="exclamation-box-heading">{{ headline }}</h3>
                    <div class="exclamation-box-description">
                        <!-- Use ng-content to place description so that anchors and html can be used. -->
                        <ng-content></ng-content>
                    </div>
                    <div class="exclamation-box-button-row">
                        <a class="exclamation-box-button button-solid light" href="{{ buttonLink }}">
                            {{ buttonTitle }}
                        </a>
                        <a
                            *ngIf="secondButtonTitle"
                            class="exclamation-box-button button-solid"
                            href="{{ secondButtonLink }}">
                            {{ secondButtonTitle }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
